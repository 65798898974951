import React, { memo } from 'react';
import cn from 'classnames';
import headersStyles from '@dealroadshow/uikit/core/styles/headers.scss';

interface IProps {
  children?: React.ReactNode,
  isH1?: boolean,
  isH2?: boolean,
  isH3?: boolean,
  className?: string,
  dataTest?: string,
}

const Title = ({
  children,
  isH1,
  isH2 = true,
  isH3,
  className,
  dataTest = '',
}: IProps) => {
  const titleClassName = cn(
    className,
    {
      [headersStyles.isH2]: isH2 || isH1,
      [headersStyles.isH3]: isH3,
    },
  );
  if (isH1) {
    return (
      <h1 className={ titleClassName } data-test={ dataTest }>
        { children }
      </h1>
    );
  }
  return (
    <div className={ titleClassName } data-test={ dataTest }>
      { children }
    </div>
  );
};

export default memo(Title);
