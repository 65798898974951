import React from 'react';
import cn from 'classnames';
import Button from '@dealroadshow/uikit/core/components/Button';
import { Icon, IconType } from '@dealroadshow/uikit';
import useGetLoginUrl from '@/finsight/ui/common/components/header/Header/UserProfile/useGetLoginUrl';

import styles from './logInButton.scss';

interface IProps {
  dataTest: string,
  variant?: string,
  withIcon?: boolean,
  className?: string,
}

const LogInButton = ({ dataTest, className, variant = 'action', withIcon = true }: IProps) => {
  const loginUrl = useGetLoginUrl();

  return (
    <a href={ loginUrl }>
      <Button
        className={ cn(styles.loginButton, className) }
        variant={ variant }
        dataTest={ dataTest }
      >
        Log In
        { withIcon && (
          <Icon
            className={ styles.lockIcon }
            type={ IconType.lock }
          />
        ) }
      </Button>
    </a>
  );
};

export default LogInButton;
