import cn from 'classnames';
import CheckUserPlanAccess from '@/finsight/ui/common/components/CheckUserPlanAccess';
import NoAccessOverlay from '@/finsight/ui/common/components/NoAccessOverlay';
import MissingSomethingModalButton from '@/finsight/ui/common/components/MissingSomethingModalButton';
import { PageContentLayout } from '@/finsight/ui/common/components/ContentLayouts';
import PageHeaderWrapper from '@/finsight/ui/common/components/PageHeaderWrapper';
import image from '@/finsight/ui/assets/noAccess/openMarketNoAccess.webp';
import { UserPlanType } from '@/users/domain/UserPlan';

import {
  OPEN_MARKET_PAGE_TITLE,
  openMarketNoAccessDescription,
  openMarketNoAccessFeatures,
  openMarketImageCaption,
} from './constants';

import headerWrapperStyles from '@/finsight/ui/common/components/PageHeaderWrapper/pageHeaderWrapper.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

const RequiredUserPlan = () => {
  return (
    <PageContentLayout>
      <PageHeaderWrapper
        title="OpenMarket Profiles"
        className={ headerWrapperStyles.pageHeaderNoWrap }
        requiredUserPlan={ UserPlanType.STARTER }
      />
      <CheckUserPlanAccess
        requiredUserPlan={ UserPlanType.STARTER }
        fallback={ (
          <NoAccessOverlay
            title={ OPEN_MARKET_PAGE_TITLE }
            description={ openMarketNoAccessDescription }
            features={ openMarketNoAccessFeatures }
            imageCaption={ openMarketImageCaption }
            image={ image }
            isLoginButton
          />
        ) }
      />
      <MissingSomethingModalButton className={ cn(spacesStyles.ptxl, spacesStyles.pbxl) } />
    </PageContentLayout>
  );
};

export default RequiredUserPlan;
